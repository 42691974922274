<template>
  <section class="campaigns-table-wrap">
    <empty-state v-if="showEmptyResult" :title="emptyMessage" />
    <ve-table
      v-else
      :columns="columns"
      :table-data="tableData"
      :border-around="true"
      :expand-option="expandOption"
      row-key-field-name="id"
    />
    <div v-if="isLoading" class="campaign--loader">
      <spinner />
      <span>{{ $t('CAMPAIGN.LIST.LOADING_MESSAGE') }}</span>
    </div>
  </section>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import Label from 'dashboard/components/ui/Label';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import WootButton from 'dashboard/components/ui/WootButton.vue';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import InboxName from 'dashboard/components/widgets/InboxName';
import campaignMixin from 'shared/mixins/campaignMixin';
import timeMixin from 'dashboard/mixins/time';
import OnlineWaMassCampaignDetails from "./OnlineWaMassCampaignDetails";
import localeMixin from "../../../../../../shared/mixins/localeMixin";

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
  },

  mixins: [clickaway, timeMixin, campaignMixin, messageFormatterMixin, localeMixin],

  props: {
    campaigns: {
      type: Array,
      default: () => [],
    },
    showEmptyResult: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandOption: {
        defaultExpandedRowKeys: [0],
        render: ({ row, column, rowIndex }, h) => {
          return <OnlineWaMassCampaignDetails campaign={row} />;
        },
      },
    }
  },
  computed: {
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },
    inboxes() {
      if (this.isOngoingType) {
        return this.$store.getters['inboxes/getWebsiteInboxes'];
      }
      return this.$store.getters['inboxes/getTwilioInboxes'];
    },
    emptyMessage() {
      if (this.isOngoingType) {
        return this.inboxes.length
          ? this.$t('CAMPAIGN.ONGOING.404')
          : this.$t('CAMPAIGN.ONGOING.INBOXES_NOT_FOUND');
      }
      if (this.isOnOffType) {
        return this.inboxes.length
          ? this.$t('CAMPAIGN.ONE_OFF.404')
          : this.$t('CAMPAIGN.ONE_OFF.INBOXES_NOT_FOUND');
      }
      return this.$t('CAMPAIGN.404');
    },
    tableData() {
      if (this.isLoading) {
        return [];
      }
      return this.campaigns.map(item => {
        const scheduledAt = item.scheduled_at
          ? this.messageStampFull(new Date(item.scheduled_at))
          : '---';
        return {
          ...item,
          msgDelay: item.trigger_rules.msg_delay,
          scheduledAt,
        };
      });
    },
    columns() {
      const visibleToAllTable = [
        {
          field: "",
          key: "a",
          type: "expand",
          title: "",
          width: 50,
          align: "center",
        },
        {
          field: 'title',
          key: 'title',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.TITLE'),
          fixed: 'left',
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div class="row--title-block">
              <h6 class="sub-block-title title text-truncate">{row.title}</h6>
            </div>
          ),
        },

        {
          field: 'message',
          key: 'message',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.MESSAGE'),
          align: 'auto',
          //width: 350,
          renderBodyCell: ({ row }) => {
            if (row.message) {
              return (
                <div>
                  <span
                    domPropsInnerHTML={this.formatMessage(row.message)}
                  ></span>
                </div>
              );
            }
            return '';
          },
        },
        {
          field: 'inbox',
          key: 'inbox',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.INBOX'),
          align: 'left',
          renderBodyCell: ({ row }) => {
            return <InboxName inbox={row.inbox} />;
          },
        },
      ];

      return [
        ...visibleToAllTable,
        {
          field: 'campaign_status',
          key: 'campaign_status',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.STATUS'),
          align: 'left',
          renderBodyCell: ({ row }) => {
            let labelText = ''
            let colorScheme = ''
            switch (row.campaign_status) {
              case 'active':
                labelText = this.$t('CAMPAIGN.LIST.STATUS.ACTIVE_WAITING');
                colorScheme = 'success';
                break;
              case 'in_process':
                labelText = this.$t('CAMPAIGN.LIST.STATUS.IN_PROCESS');
                colorScheme = 'warning';
                break;
              case 'completed':
                labelText = this.$t('CAMPAIGN.LIST.STATUS.COMPLETED');
                colorScheme = 'primary';
                break;
            }
            if (!row.enabled) {
              labelText = this.$t('CAMPAIGN.LIST.STATUS.DISABLED');
              colorScheme = 'secondary';
            }
            return <Label title={labelText} colorScheme={colorScheme} />;
          },
        },
        {
          field: 'scheduledAt',
          key: 'scheduledAt',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.SCHEDULED_AT'),
          align: 'left',
          renderBodyCell: ({ row }) => {
            return <span dir="ltr">{row.scheduledAt}</span>;
          },
        },
        {
          field: 'buttons',
          key: 'buttons',
          title: '',
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div class="button-wrapper">
              <woot-button
                is-disabled={row.campaign_status !== 'active'}
                variant="clear"
                icon="ion-edit"
                color-scheme="secondary"
                classNames="grey-btn"
                onClick={() => this.$emit('on-edit-click', row)}
              >
                {this.$t('CAMPAIGN.LIST.BUTTONS.EDIT')}
              </woot-button>
              <woot-button
                variant="link"
                icon="ion-close-circled"
                color-scheme="secondary"
                onClick={() => this.$emit('on-delete-click', row)}
              >
                {this.$t('CAMPAIGN.LIST.BUTTONS.DELETE')}
              </woot-button>
            </div>
          ),
        },
      ];

    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns-table-wrap::v-deep {
  .ve-table {

    thead.ve-table-header .ve-table-header-tr .ve-table-header-th {
      font-size: var(--font-size-mini);
      padding: var(--space-small) var(--space-two);
    }
    tbody.ve-table-body .ve-table-body-tr .ve-table-body-td {
      padding: var(--space-slab) var(--space-two);
    }

    table.ve-table-content {
      overflow: hidden;
      table-layout: auto;
    }
  }

  .row--title-block {
    align-items: center;
    display: flex;
    text-align: left;

    .title {
      font-size: var(--font-size-small);
      margin: 0;
      text-transform: capitalize;
    }
  }
  .label {
    padding: var(--space-smaller) var(--space-small);
  }
}

.campaign--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.button-wrapper {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  min-width: 20rem;
}
</style>
