var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col h-full items-start md:items-center"},[_c('div',{staticClass:"flex flex-col text-center items-center justify-start m-0 mx-4 flex-1"},[_c('img',{staticClass:"p-2 h-16 w-16",attrs:{"src":_vm.getIntegrationLogo}}),_vm._v(" "),_c('div',[_c('h3',{staticClass:"text-xl text-slate-800 dark:text-slate-100"},[_vm._v("\n        "+_vm._s(_vm.getIntegrationName)+"\n      ")]),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.useInstallationName(
            _vm.integrationDescription,
            _vm.globalConfig.installationName
          ))+"\n      ")])])]),_vm._v(" "),(_vm.integrationId === 'openai')?_c('div',[(_vm.hasConnectedHooks)?_c('div',{staticClass:"flex justify-center items-center mb-0"},[(_vm.hasConnectedHooks)?_c('woot-button',{staticClass:"margin-horizontal-2",attrs:{"icon":"edit","color-scheme":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.onSettings.apply(null, arguments)}}},[_vm._v("\n        "+_vm._s(_vm.$t('COMMAND_BAR.SECTIONS.SETTINGS'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.hasConnectedHooks)?_c('div',[_c('div',{on:{"click":_vm.openDeletePopupApp}},[_c('woot-button',{staticClass:"nice alert"},[_vm._v("\n            "+_vm._s(_vm.$t('INTEGRATION_APPS.DISCONNECT.BUTTON_TEXT'))+"\n          ")])],1)]):_vm._e()],1):_c('div',{staticClass:"flex flex-row justify-center items-center mb-0"},[_c('woot-button',{attrs:{"color-scheme":"success"},on:{"click":function($event){$event.preventDefault();return _vm.toggleChatGptEnableModal.apply(null, arguments)}}},[_vm._v("\n        "+_vm._s(_vm.$t('INTEGRATION_APPS.CONNECT.BUTTON_TEXT'))+"\n      ")])],1)]):_c('div',{staticClass:"flex justify-center items-center mb-0 w-[15%]"},[_c('router-link',{attrs:{"to":_vm.frontendURL(
          `accounts/${_vm.accountId}/${_vm.integrationPath || 'settings/integrations'}/` + _vm.integrationId
        )}},[(_vm.integrationEnabled)?_c('div',[(_vm.integrationAction === 'disconnect')?_c('div',[_c('div',{on:{"click":_vm.openDeletePopup}},[_c('woot-submit-button',{attrs:{"button-text":_vm.actionButtonText ||
                  _vm.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.BUTTON_TEXT'),"button-class":"smooth alert"}})],1)]):_c('div',[_c('button',{staticClass:"button nice",attrs:{"disabled":_vm.disabled}},[_vm._v("\n            "+_vm._s(_vm.$t('INTEGRATION_SETTINGS.WEBHOOK.CONFIGURE'))+"\n          ")])])]):_vm._e()]),_vm._v(" "),(!_vm.integrationEnabled)?_c('div',[_c('a',{staticClass:"button success nice",attrs:{"href":_vm.integrationAction}},[_vm._v("\n        "+_vm._s(_vm.$t('INTEGRATION_SETTINGS.CONNECT.BUTTON_TEXT'))+"\n      ")])]):_vm._e()],1),_vm._v(" "),_c('woot-delete-modal',{attrs:{"show":_vm.showDeleteConfirmationPopup,"on-close":_vm.closeDeletePopup,"on-confirm":_vm.confirmDeletion,"title":_vm.deleteConfirmationTextApp.title || _vm.deleteConfirmationText.title ||
        _vm.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.TITLE'),"message":_vm.deleteConfirmationTextApp.message || _vm.deleteConfirmationText.message ||
        _vm.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.MESSAGE'),"confirm-text":_vm.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.YES'),"reject-text":_vm.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.NO')},on:{"update:show":function($event){_vm.showDeleteConfirmationPopup=$event}}}),_vm._v(" "),(_vm.showChatGptEnableModal)?_c('chat-gpt-enable-modal',{attrs:{"show":_vm.showChatGptEnableModal},on:{"close":_vm.toggleChatGptEnableModal}}):_vm._e(),_vm._v(" "),_c('woot-modal',{attrs:{"show":_vm.showDAssistanceSettings,"on-close":_vm.toggleDAssistanceShow},on:{"update:show":function($event){_vm.showDAssistanceSettings=$event}}},[_c('woot-modal-header',{attrs:{"header-title":_vm.$t('D_ASSISTANCE.SETTINGS.TITLE'),"header-content":_vm.$t('D_ASSISTANCE.SETTINGS.NOTE')}}),_vm._v(" "),(_vm.showDAssistanceSettings)?_c('digital99-assistance-setting',{on:{"cancel":_vm.toggleDAssistanceShow}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }