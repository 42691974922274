<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header
        :header-title="$t('AGENT_MGMT.ADD.TITLE')"
        :header-content="$t('AGENT_MGMT.ADD.DESC')"
      />

      <form
        class="flex flex-col w-full items-start"
        @submit.prevent="addAgent()"
      >
        <div class="w-full">
          <label :class="{ error: $v.agentName.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.NAME.LABEL') }}
            <input
              v-model.trim="agentName"
              type="text"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
              @input="$v.agentName.$touch"
            />
          </label>
        </div>
        <div class="w-full">
          <label :class="{ error: $v.agentType.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.LABEL') }}
            <select v-model="agentType">
              <option v-for="role in roles" :key="role.name" :value="role.name">
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentType.$error" class="message">
              {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.ERROR') }}
            </span>
          </label>
          <label v-if="agentType === 'agent'">
            <input v-model="agentSeeAllConversations" type="checkbox" />
            {{ $t('AGENT_MGMT.EDIT.FORM.SEE_ALL_CONVERSATIONS.LABEL') }}
          </label>
        </div>
        <div class="w-full">
          <label :class="{ error: $v.agentEmail.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.EMAIL.LABEL') }}
            <input
              v-model.trim="agentEmail"
              type="text"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.EMAIL.PLACEHOLDER')"
              @input="$v.agentEmail.$touch"
            />
          </label>
        </div>
        <div class="w-full mb-4 relative">
          <label>
            {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_SKILLS.LABEL') }}
            <custom-attribute-selector
              attribute-type="agent_attribute"
              :init-custom-attributes="customAttributes"
              @addAttribute="addAttribute"
            />
            <custom-attributes
              attribute-type="agent_attribute"
              attribute-class="conversation--attribute"
              :init-custom-attributes="customAttributes"
              class="even"
              @changeAttributes="changeAttributes"
            />
          </label>
        </div>

        <div class="w-full">
          <label :class="{ error: $v.agentTwoFactorType.$error }">
            2FA
            <select v-model="agentTwoFactorType">
              <option value="" :disabled="isMandatory2fa" selected>
                {{ $t(`AGENT_MGMT.EDIT.FORM.AGENT_TWO_FACTOR_AUTH.OFF`) }}
              </option>
              <option v-for="type in ['sms', 'email']" :key="type" :value="type">
                {{ $t(`AGENT_MGMT.EDIT.FORM.AGENT_TWO_FACTOR_AUTH.${type}`) }}
              </option>
            </select>
          </label>
        </div>

        <div v-show="agentTwoFactorType" class="py-3 px-4 bg-slate-25 w-full">
          <div v-show="agentTwoFactorType === 'sms'" class="w-full">
            <div v-if="!$v.agentTwoFactorType.emptyPhone" class="error text-center">
              <span class="message">
                {{ $t('AGENT_MGMT.EDIT.FORM.AUTH_PHONE.ERROR.EMPTY_PHONE') }}
              </span>
            </div>
            <div class="w-full">
              <label :class="{ error: $v.agentPhone1.$error }">
                {{ $t('AGENT_MGMT.EDIT.FORM.PHONE.LABEL_PHONE1') }}
                <input
                  v-model="agentPhone1"
                  type="text"
                  @input="$v.agentPhone1.$touch"
                  :placeholder="$t('AGENT_MGMT.EDIT.FORM.PHONE.PLACEHOLDER')"
                />
                <span v-if="$v.agentPhone1.$error" class="message">
                  {{ $t('AGENT_MGMT.EDIT.FORM.AUTH_PHONE.ERROR.FORMAT') }}
                </span>
              </label>
            </div>
          </div>
          <div v-show="agentTwoFactorType === 'email'" class="w-full">
            <div v-if="!$v.agentTwoFactorType.emptyEmail" class="error text-center">
              <span class="message">
                {{ $t('AGENT_MGMT.EDIT.FORM.AUTH_PHONE.ERROR.EMPTY_EMAIL') }}
              </span>
            </div>
            <div class="w-full">
              <label :class="{ error: $v.agentEmail1.$error }">
                {{ $t('AGENT_MGMT.EDIT.FORM.EMAIL.2FA') }}
                <input
                  v-model="agentEmail1"
                  type="email"
                  @input="$v.agentEmail1.$touch"
                  :placeholder="$t('AGENT_MGMT.ADD.FORM.EMAIL.PLACEHOLDER')"
                />
              </label>
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <div class="w-full">
            <woot-submit-button
              :disabled="
                $v.agentEmail.$invalid ||
                $v.agentName.$invalid ||
                $v.agentTwoFactorType.$invalid ||
                $v.agentPhone1.$invalid ||
                uiFlags.isCreating
              "
              :button-text="$t('AGENT_MGMT.ADD.FORM.SUBMIT')"
              :loading="uiFlags.isCreating"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('AGENT_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
        <div class="w-full footer-text">
          <span
            class="font-semibold text-sm text-slate-600 dark:text-slate-300"
          >
            {{ $t('AGENT_MGMT.ADD.FOOTER') }}
          </span>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { isPhoneIsraelEmpty } from 'shared/helpers/Validators';
import { mapGetters } from 'vuex';
import CustomAttributes from 'dashboard/routes/dashboard/conversation/customAttributes/CustomAttributes.vue';
import CustomAttributeSelector from 'dashboard/routes/dashboard/conversation/customAttributes/CustomAttributeSelector.vue';

export default {
  components: {
    CustomAttributes,
    CustomAttributeSelector,
  },
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      agentName: '',
      agentEmail: '',
      agentType: 'agent',
      agentSeeAllConversations: false,
      vertical: 'bottom',
      horizontal: 'center',
      // agentTwoFactorAuth: false,
      agentTwoFactorType: '',
      agentPhone1: '',
      agentEmail1: '',
      roles: [
        {
          name: 'administrator',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
        },
        {
          name: 'agent',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
        },
      ],
      show: true,
      customAttributes: {},
    };
  },
  // d99d
  mounted() {
    if (this.isMandatory2fa) {
      this.agentTwoFactorType = 'sms';
    }
  },
  // .
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      getAccount: 'accounts/getAccount', // d99d
      accountId: 'getCurrentAccountId', // d99d
    }),
    // d99d
    isMandatory2fa() {
      return this.getAccount(this.accountId)?.mandatory_2fa
    },
    // .
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(1),
    },
    agentEmail: {
      required,
      email,
    },
    agentType: {
      required,
    },
    agentPhone1: {
      isPhoneIsraelEmpty,
    },
    agentEmail1: {
      required,
      email,
    },
    agentTwoFactorType: {
      emptyPhone: function (value) {
        return value !== 'sms' || !!this.agentPhone1;
      },
      emptyEmail: function (value) {
        return value !== 'email' || !!this.agentEmail1;
      },
    },
  },
  watch: {
    agentTwoFactorType(newVal) {
      if (newVal === 'email' && !this.agentEmail1) {
        this.agentEmail1 = this.agentEmail;
      }
    },
  },
  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async addAgent() {
      if (this.agentType === 'administrator') {
        // d99d
        this.agentSeeAllConversations = undefined;
      }
      try {
        await this.$store.dispatch('agents/create', {
          name: this.agentName,
          email: this.agentEmail,
          role: this.agentType,
          custom_attributes: this.customAttributes,
          two_factor_type: this.agentTwoFactorType, // d99d
          phone1: this.agentPhone1, // d99d
          email1: this.agentEmail1, // d99d
          see_all_conversations: this.agentSeeAllConversations, // d99d
        });
        this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const { response: { data: { error: errorResponse = '' } = {} } = {} } =
          error;
        let errorMessage = '';
        if (error.response.status === 422) {
          errorMessage = this.$t('AGENT_MGMT.ADD.API.EXIST_MESSAGE');
        } else {
          errorMessage = this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE');
        }
        this.showAlert(errorResponse || errorMessage);
      }
    },
    addAttribute(attributeKey, attributeValue) {
      this.$set(this.customAttributes, attributeKey, attributeValue);
    },
    changeAttributes(customAttrs) {
      this.customAttributes = customAttrs;
    },
  },
};
</script>
<style scoped>
.footer-text {
  white-space: pre-line;
}
</style>
