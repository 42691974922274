import fromUnixTime from 'date-fns/fromUnixTime';
import differenceInDays from 'date-fns/differenceInDays';
import Cookies from 'js-cookie';
import {
  ANALYTICS_IDENTITY,
  ANALYTICS_RESET,
  CHATWOOT_RESET,
  CHATWOOT_SET_USER,
} from '../../helper/scriptHelpers';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';
import { LocalStorage } from 'shared/helpers/localStorage';

Cookies.defaults = { sameSite: 'Lax' };

//d99d
if (window.location.origin === window.globalConfig.FRAME_FRONTEND_URL)  Cookies.defaults = { sameSite: 'None', secure: true}

export const getLoadingStatus = state => state.fetchAPIloadingStatus;
export const setLoadingStatus = (state, status) => {
  state.fetchAPIloadingStatus = status;
};

export const setUser = (user, expiryDate) => {
  window.bus.$emit(CHATWOOT_SET_USER, { user });
  window.bus.$emit(ANALYTICS_IDENTITY, { user });

  if (!Cookies.get('user')) Cookies.remove('cw_conversation') // d99d otherwise someone else's support chat remains

  Cookies.set('user', {email: user.email}, { // d99d
    expires: differenceInDays(expiryDate, new Date()),
  });
};

export const getHeaderExpiry = response =>
  fromUnixTime(response.headers.expiry);

export const setAuthCredentials = response => {
  const expiryDate = getHeaderExpiry(response);
  Cookies.set('cw_d_session_info', response.headers, {
    expires: differenceInDays(expiryDate, new Date()),
  });
  setUser(response.data.data, expiryDate);
};

export const clearBrowserSessionCookies = () => {
  Cookies.remove('cw_d_session_info');
  Cookies.remove('auth_data');
  Cookies.remove('user');

  // d99d remove all cw_ cookies of this site (needs for web widget)
  Object.keys(Cookies.get()).forEach(function(cookieName) {
    if (cookieName.startsWith('cw_')) Cookies.remove(cookieName);
  });
};

export const clearLocalStorageOnLogout = () => {
  LocalStorage.remove(LOCAL_STORAGE_KEYS.DRAFT_MESSAGES);
};

export const deleteIndexedDBOnLogout = async () => {
  const dbs = await window.indexedDB.databases();
  dbs.forEach(db => {
    window.indexedDB.deleteDatabase(db.name);
  });
};

export const clearCookiesOnLogout = () => {
  window.bus.$emit(CHATWOOT_RESET);
  window.bus.$emit(ANALYTICS_RESET);
  clearBrowserSessionCookies();
  clearLocalStorageOnLogout();
  const globalConfig = window.globalConfig || {};
  const logoutRedirectLink = globalConfig.LOGOUT_REDIRECT_LINK || '/';
  window.location = logoutRedirectLink;
};

export const parseAPIErrorResponse = error => {
  if (error?.response?.data?.message) {
    return error?.response?.data?.message;
  }
  if (error?.response?.data?.error) {
    return error?.response?.data?.error;
  }
  if (error?.response?.data?.errors) {
    return error?.response?.data?.errors[0];
  }
  return error;
};

export const throwErrorMessage = error => {
  const errorMessage = parseAPIErrorResponse(error);
  throw new Error(errorMessage);
};
