<template>
  <div class="flex bg-slate-25">
    <woot-wizard
      class="small-3 columns p-5"
      :items="$t('EXPORT_WIZARD.STEPS')"
      :step="step"
    />
    <div class="wizard-body w-[75%] max-w-[75%] !h-auto">
      <Categories
        v-if="step === 0"
        :categories="categories"
        @onChangeCategory="onChangeCategory"
      />
      <ConversationFilters
        v-if="showConversationFilters"
        ref="conversationFilters"
        :filters="filters"
        :skip-show-filters="skipShowFilters"
        @onFiltersChange="onFiltersChange"
      />
      <ContactFilters
        v-if="showContactFilters"
        ref="contactFilters"
        :filters="filters"
        @onFiltersChange="onFiltersChange"
      />
      <Export
        v-if="step === 2"
        ref="export"
        :category="category"
        :filters="filters"
        @readyToDownload="readyToDownload"
      />

      <div class="w-full">
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <woot-button
            v-if="step > 0"
            class="button clear"
            @click.prevent="back"
          >
            {{ $t('EXPORT_WIZARD.BACK') }}
          </woot-button>
          <woot-button v-if="step < 2" @click="next">
            {{ $t('EXPORT_WIZARD.NEXT') }}
          </woot-button>
          <woot-button v-if="canDownload" @click="download">
            {{ $t('EXPORT_WIZARD.DOWNLOAD') }}
          </woot-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import Categories from './Categories.vue';
import ConversationFilters from './ConversationFilters.vue';
import ContactFilters from './ContactFilters.vue';
import Export from './Export.vue';

export default {
  components: {
    Export,
    Categories,
    ContactFilters,
    ConversationFilters,
  },
  mixins: [clickaway],
  data() {
    return {
      step: 0,
      filters: [],
      category: 'conversations',
      downloadConversationInfo: {},
      categories: [],
      fileReady: false,
      skipShowFilters: false,
    };
  },
  computed: {
    showConversationFilters() {
      return this.step === 1 && this.category === 'conversations';
    },
    showContactFilters() {
      return this.step === 1 && this.category === 'contacts';
    },
    canDownload() {
      return this.step === 2 && this.fileReady;
    },
  },
  watch: {
    category: {
      handler() {
        this.filters = [];
      },
    },
  },
  mounted() {
    this.categories = this.$t('EXPORT_WIZARD.STEP_1.CATEGORIES').map(cat => {
      return { ...cat, checked: cat.key === this.category };
    });
  },
  methods: {
    onChangeCategory(category) {
      this.category = category;
      this.categories.forEach(cat => {
        cat.checked = cat.key === category;
      });
    },
    onFiltersChange(filters) {
      this.filters = filters;
      this.skipShowFilters = true;
    },
    canNext() {
      if (this.showConversationFilters) {
        return this.$refs.conversationFilters.isFiltersValid();
      }
      if (this.showContactFilters) {
        return this.$refs.contactFilters.isFiltersValid();
      }
      return true;
    },
    next() {
      if (this.canNext()) this.step += 1;
    },
    back() {
      this.step -= 1;
      this.fileReady = false;
    },
    download() {
      if (this.$refs.export) this.$refs.export.autoDownloadFile();
    },
    readyToDownload(isReady) {
      this.fileReady = isReady;
    },
  },
};
</script>

<style scoped lang="scss">
.basic-filter {
  @apply w-52 p-4 top-6;
}
</style>
