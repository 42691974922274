export const INBOX_TYPES = {
  WEB: 'Channel::WebWidget',
  FB: 'Channel::FacebookPage',
  FB2: 'Channel::FacebookPage2', // d99d
  TWITTER: 'Channel::TwitterProfile',
  TWILIO: 'Channel::TwilioSms',
  WHATSAPP: 'Channel::Whatsapp',
  API: 'Channel::Api',
  EMAIL: 'Channel::Email',
  TELEGRAM: 'Channel::Telegram',
  LINE: 'Channel::Line',
  SMS: 'Channel::Sms',
  TIKTOK: 'Channel::TiktokProfile'
};

export const INBOX_FEATURES = {
  REPLY_TO: 'replyTo',
};

// This is a single source of truth for inbox features
// This is used to check if a feature is available for a particular inbox or not
export const INBOX_FEATURE_MAP = {
  [INBOX_FEATURES.REPLY_TO]: [
    INBOX_TYPES.WEB,
    INBOX_TYPES.TWITTER,
    INBOX_TYPES.WHATSAPP,
    INBOX_TYPES.LINE,
    INBOX_TYPES.TELEGRAM,
    INBOX_TYPES.API,
    INBOX_TYPES.FB2, // d99d
    INBOX_TYPES.TIKTOK, // d99d
  ],
};

export default {
  computed: {
    channelType() {
      return this.inbox.channel_type;
    },
    whatsAppAPIProvider() {
      return this.inbox.provider || '';
    },
    isAPIInbox() {
      return this.channelType === INBOX_TYPES.API;
    },
    isOnlineWaInbox() { //deprecated
      return this.inbox.online_wa;
    },
    isSomeOfOnlineWaInbox() { //to replace function above
      return this.inbox.api_type && this.inbox.api_type.charAt(0) === 'w';
    },
    isOnlineWaInboxV1() {
      return this.inbox.api_type === 'wa_v1';
    },
    isOnlineWaInboxV13() {
      return ['wa_v1', 'wa_v3'].includes(this.inbox.api_type);
    },
    isOnlineWaInboxV23() {
      return ['wa_v2', 'wa_v3'].includes(this.inbox.api_type);
    },
    isOnlineWaInboxV2() {
      return this.inbox.api_type === 'wa_v2';
    },
    isATwitterInbox() {
      return this.channelType === INBOX_TYPES.TWITTER;
    },
    isAFacebookInbox() {
      return this.channelType === INBOX_TYPES.FB;
    },
    // d99d
    isAFacebook2Inbox() {
      return this.channelType === 'Channel::FacebookPage2';
    },
    isATiktokChannel() {
      return this.channelType === INBOX_TYPES.TIKTOK;
    },
    isAGmailChannel() {
      return this.channelType === INBOX_TYPES.EMAIL && this.inbox.api_type === 'gmail';
    },
    isAMicrosoftApiChannel() {
      return this.channelType === INBOX_TYPES.EMAIL && this.inbox.api_type === 'microsoft_api';
    },
    isAnEmailApiChannel() {
      return this.channelType === INBOX_TYPES.EMAIL && this.inbox.api_type?.length > 0;
    },
    // .
    isAWebWidgetInbox() {
      return this.channelType === INBOX_TYPES.WEB;
    },
    isATwilioChannel() {
      return this.channelType === INBOX_TYPES.TWILIO;
    },
    isALineChannel() {
      return this.channelType === INBOX_TYPES.LINE;
    },
    isAnEmailChannel() {
      return this.channelType === INBOX_TYPES.EMAIL;
    },
    isATelegramChannel() {
      return this.channelType === INBOX_TYPES.TELEGRAM;
    },
    isATwilioSMSChannel() {
      const { medium: medium = '' } = this.inbox;
      return this.isATwilioChannel && medium === 'sms';
    },
    isASmsInbox() {
      return this.channelType === INBOX_TYPES.SMS || this.isATwilioSMSChannel;
    },
    // d99d
    isA99digitalSmsInbox() {
      return (
        this.channelType === INBOX_TYPES.SMS &&
        this.inbox.provider === '99digital'
      );
    },
    isATwilioWhatsAppChannel() {
      const { medium: medium = '' } = this.inbox;
      return this.isATwilioChannel && medium === 'whatsapp';
    },
    isAWhatsAppCloudChannel() {
      return (
        this.channelType === INBOX_TYPES.WHATSAPP &&
        this.whatsAppAPIProvider === 'whatsapp_cloud'
      );
    },
    is360DialogWhatsAppChannel() {
      return (
        this.channelType === INBOX_TYPES.WHATSAPP &&
        this.whatsAppAPIProvider === 'default'
      );
    },
    chatAdditionalAttributes() {
      const { additional_attributes: additionalAttributes } = this.chat || {};
      return additionalAttributes || {};
    },
    isTwitterInboxTweet() {
      return this.chatAdditionalAttributes.type === 'tweet';
    },
    twilioBadge() {
      return `${this.isATwilioSMSChannel ? 'sms' : 'whatsapp'}`;
    },
    twitterBadge() {
      return `${this.isTwitterInboxTweet ? 'twitter-tweet' : 'twitter-dm'}`;
    },
    facebookBadge() {
      return this.chatAdditionalAttributes.type || 'facebook';
    },
    inboxBadge() {
      let badgeKey = '';
      if (this.isATwitterInbox) {
        badgeKey = this.twitterBadge;
      } else if (this.isAFacebookInbox) {
        badgeKey = this.facebookBadge;
      } else if (this.isAFacebook2Inbox) { // d99d added isAFacebook2Inbox
        badgeKey = this.chatAdditionalAttributes.type || 'facebook_comment';
      } else if (this.isATwilioChannel) {
        badgeKey = this.twilioBadge;
      } else if (this.isAWhatsAppChannel) {
        badgeKey = 'whatsapp';
      }
      return badgeKey || this.channelType;
    },
    isAWhatsAppChannel() {
      return (
        this.channelType === INBOX_TYPES.WHATSAPP ||
        this.isATwilioWhatsappChannel ||
        this.isSomeOfOnlineWaInbox
      );
    },
  },
  methods: {
    inboxHasFeature(feature) {
      return INBOX_FEATURE_MAP[feature]?.includes(this.channelType) ?? false;
    },
  },
};
