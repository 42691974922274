<template>
  <header
    class="bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800"
  >
    <div class="flex justify-between w-full py-2 px-4">
      <div class="flex items-center justify-center max-w-full min-w-[6.25rem]">
        <woot-sidemenu-icon />
        <h1
          class="m-0 text-xl text-slate-900 dark:text-slate-100 overflow-hidden whitespace-nowrap text-ellipsis my-0 mx-2"
        >
          {{ headerTitle }}
        </h1>
      </div>
      <div class="flex gap-2">
        <div
          class="max-w-[400px] min-w-[150px] flex items-center relative mx-2 search-wrap"
        >
          <div class="flex items-center absolute h-full left-2.5">
            <fluent-icon
              icon="search"
              class="h-5 leading-9 text-sm text-slate-700 dark:text-slate-200"
            />
          </div>
          <input
            type="text"
            :placeholder="$t('CONTACTS_PAGE.SEARCH_INPUT_PLACEHOLDER')"
            class="contact-search border-slate-100 dark:border-slate-600"
            :value="searchQuery"
            @keyup.enter="submitSearch"
            @input="inputSearch"
          />
          <woot-button
            :is-loading="false"
            class="clear"
            :class-names="searchButtonClass"
            @click="submitSearch"
          >
            {{ $t('CONTACTS_PAGE.SEARCH_BUTTON') }}
          </woot-button>
        </div>
        <div v-if="hasActiveSegments" class="flex gap-2">
          <woot-button
            class="clear"
            color-scheme="secondary"
            icon="edit"
            @click="onToggleEditSegmentsModal"
          >
            {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_EDIT') }}
          </woot-button>
          <woot-button
            class="clear"
            color-scheme="alert"
            icon="delete"
            @click="onToggleDeleteSegmentsModal"
          >
            {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_DELETE') }}
          </woot-button>
        </div>
        <div v-if="!hasActiveSegments" class="relative">
          <div
            v-if="hasAppliedFilters"
            class="absolute h-2 w-2 top-1 right-3 bg-slate-500 dark:bg-slate-500 rounded-full"
          />
          <woot-button
            class="clear"
            color-scheme="secondary"
            data-testid="create-new-contact"
            icon="filter"
            @click="toggleFilter"
          >
            {{ $t('CONTACTS_PAGE.FILTER_CONTACTS') }}
          </woot-button>
        </div>

        <woot-button
          v-if="hasAppliedFilters && !hasActiveSegments"
          class="clear"
          color-scheme="alert"
          variant="clear"
          icon="save"
          @click="onToggleSegmentsModal"
        >
          {{ $t('CONTACTS_PAGE.FILTER_CONTACTS_SAVE') }}
        </woot-button>
        <woot-button
          class="clear"
          color-scheme="success"
          icon="person-add"
          data-testid="create-new-contact"
          @click="toggleCreate"
        >
          {{ $t('CREATE_CONTACT.BUTTON_LABEL') }}
        </woot-button>

        <woot-button
          v-if="isAdmin"
          color-scheme="info"
          icon="upload"
          class="clear"
          @click="toggleImport"
        >
          {{ $t('IMPORT_CONTACTS.BUTTON_LABEL') }}
        </woot-button>
        <div v-if="false/* d99d */" style="position: relative">
            <woot-button
                    color-scheme="info"
                    class="clear"
                    icon="ion-android-download"
                    :is-disabled="isExportInProcess"
                    @click="downloadContacts">
                {{ $t('CONTACTS_PAGE.DOWNLOAD_CONTACTS') }}
            </woot-button>
            <div v-if="isExportInProcess || downloadContactsInfo.status === 'failed'" class="label--export-process text-xs" :class="{error: downloadContactsInfo.status === 'failed'}">
                {{downloadContactsInfo.message}}
            </div>
       </div>

        <woot-button
          v-if="false /* d99d */ && isAdmin"
          color-scheme="info"
          icon="download"
          class="clear"
          @click="submitExport"
        >
          {{ $t('EXPORT_CONTACTS.BUTTON_LABEL') }}
        </woot-button>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [adminMixin, alertMixin],
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    searchQuery: {
      type: String,
      default: '',
    },
    segmentsId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      showCreateModal: false,
      showImportModal: false,
      refreshTimer: undefined,
      downloadContactsInfo: {},
    };
  },
  destroyed() {
    clearTimeout(this.refreshTimer)
  },
  computed: {
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
    ...mapGetters({
      getAppliedContactFilters: 'contacts/getAppliedContactFilters',
    }),
    hasAppliedFilters() {
      return this.getAppliedContactFilters.length;
    },
    hasActiveSegments() {
      return this.segmentsId !== 0;
    },
    isExportInProcess() {
      return this.getIsExportInProcess(this.downloadContactsInfo.status)
    }
  },
  methods: {
    // d99d
    getIsExportInProcess(status) {
      return ['pending', 'processing'].includes(status)
    },
    async downloadContacts() {
      try {
        await this.$store.dispatch('contacts/downloadContacts')
        this.downloadContactsInfo = {message: this.$t('GENERAL_SETTINGS.EXPORT_ALL_CONTACTS_STARTED')}
        await this.fetchExportStatus()
      } catch (error) {
        this.downloadContactsInfo = {}
        this.showAlert(
          error.message || this.$t('IMPORT_CONVERSATIONS.ERROR_MESSAGE')
        );
      }
    },
    async fetchExportStatus() {
      try {
        const {status, message, file} = await this.$store.dispatch('contacts/statusOfLastDownloadContacts');
        this.downloadContactsInfo = {status, message, file}
        if (this.getIsExportInProcess(status)) {
          if (!this.downloadContactsInfo.message) this.downloadContactsInfo.message = this.$t('GENERAL_SETTINGS.EXPORT_ALL_CONTACTS_STARTED')
          this.refreshTimer = setTimeout(() => this.fetchExportStatus(), 1000)
        }
        if (status === 'completed' && file) {
          const link = document.createElement('a');
          link.setAttribute(
            'href',
            file.url
          );
          link.click()
          URL.revokeObjectURL(link.href);
        }
      } catch (error) {
        console.error('fetchExportStatus of Contacts', error)
      }
    },
    // .
    onToggleSegmentsModal() {
      this.$emit('on-toggle-save-filter');
    },
    onToggleEditSegmentsModal() {
      this.$emit('on-toggle-edit-filter');
    },
    onToggleDeleteSegmentsModal() {
      this.$emit('on-toggle-delete-filter');
    },
    toggleCreate() {
      this.$emit('on-toggle-create');
    },
    toggleFilter() {
      this.$emit('on-toggle-filter');
    },
    toggleImport() {
      this.$emit('on-toggle-import');
    },
    submitExport() {
      this.$emit('on-export-submit');
    },
    submitSearch() {
      this.$emit('on-search-submit');
    },
    inputSearch(event) {
      this.$emit('on-input-search', event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~widget/assets/scss/variables';

.search-wrap {
  .contact-search {
    @apply pl-9 pr-[3.75rem] text-sm w-full h-[2.375rem] m-0;
  }

  .button {
    transition: transform 100ms linear;
    @apply ml-2 h-8 right-1 absolute py-0 px-2 opacity-0 -translate-x-px invisible;
  }

  .button.show {
    @apply opacity-100 translate-x-0 visible;
  }
}

.label--export-process {
  font-style: italic;
  text-align: center;
  position: absolute;
  color: $color-primary;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 18rem;
  padding: 0 var(--space-small);
  overflow-wrap: break-word;

  &.error {
    color: $color-error;
    position: unset;
  }
}
</style>
