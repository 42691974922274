<template>
    <div class="flex-grow flex-shrink min-w-0 p-6 overflow-auto">
        <form v-if="!uiFlags.isFetchingItem" @submit.prevent="updateAccount">
            <div
        class="flex flex-row p-4 border-b border-slate-25 dark:border-slate-800"
      >
                <div
          class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 py-4 pr-6 pl-0"
        >
                    <h4 class="block-title text-black-900 dark:text-slate-200">
                        {{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.TITLE') }}
                    </h4>
                    <p>{{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.NOTE') }}</p>
                </div>
                <div class="p-4 flex-grow-0 flex-shrink-0 flex-[50%]">
                    <image-uploader v-if="showLogo"
                                    :label="$t('GENERAL_SETTINGS.FORM.LOGO.LABEL')"
                                    :src="logoUrl"
                                    delete-image
                                    :delete-title="$t('GENERAL_SETTINGS.FORM.LOGO.DELETE_BTN_TEXT')"
                                    @change="handleImageUpload"
                                    @onDelete="handleImageDelete"
                    />
                    <label :class="{ error: $v.name.$error }">
                        <div @dblclick="showLogo = true">{{ $t('GENERAL_SETTINGS.FORM.NAME.LABEL') }}</div>
                        <input
                                v-model="name"
                                type="text"
                                disabled
                                :placeholder="$t('GENERAL_SETTINGS.FORM.NAME.PLACEHOLDER')"
                                @blur="$v.name.$touch"
                        />
                        <span v-if="$v.name.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.NAME.ERROR') }}
            </span>
                    </label>
                    <label :class="{ error: $v.locale.$error }">
                        {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.LABEL') }}
                        <select v-model="locale">
                            <option
                                    v-for="lang in languagesSortedByCode"
                                    :key="lang.iso_639_1_code"
                                    :value="lang.iso_639_1_code"
                            >
                                {{ lang.name }}
                            </option>
                        </select>
                        <span v-if="$v.locale.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.ERROR') }}
            </span>
                    </label>

                    <!--          <label :class="{ error: $v.chatview.$error }">-->
                    <!--            {{ $t('GENERAL_SETTINGS.FORM.CHATVIEW.LABEL') }}-->
                    <!--            <select v-model="chatview">-->
                    <!--              <option-->
                    <!--                v-for="chatStatus in chatViewArray"-->
                    <!--                :key="chatStatus.key"-->
                    <!--                :value="chatStatus.value"-->
                    <!--              >-->
                    <!--                {{ chatStatus.name }}-->
                    <!--              </option>-->
                    <!--            </select>-->
                    <!--            <span v-if="$v.chatview.$error" class="message">-->
                    <!--              {{ $t('GENERAL_SETTINGS.FORM.CHATVIEW.ERROR') }}-->
                    <!--            </span>-->
                    <!--          </label>-->

                    <label v-if="featureCustomReplyDomainEnabled">
                        {{
                        $t('GENERAL_SETTINGS.FORM.FEATURES.CUSTOM_EMAIL_DOMAIN_ENABLED')
                        }}
                    </label>
                    <label v-if="featureCustomReplyDomainEnabled">
                        {{ $t('GENERAL_SETTINGS.FORM.DOMAIN.LABEL') }}
                        <input
                                v-model="domain"
                                type="text"
                                :placeholder="$t('GENERAL_SETTINGS.FORM.DOMAIN.PLACEHOLDER')"
                        />
                    </label>
                    <label v-if="featureCustomReplyEmailEnabled">
                        {{ $t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.LABEL') }}
                        <input
                                v-model="supportEmail"
                                type="text"
                                :placeholder="
                $t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.PLACEHOLDER')
              "
                        />
                    </label>
                    <label
                            v-if="showAutoResolutionConfig"
                            :class="{ error: $v.autoResolveDuration.$error }"
                    >
                        {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.LABEL') }}
                        <input
                                v-model="autoResolveDuration"
                                type="number"
                                :placeholder="
                $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.PLACEHOLDER')
              "
                                @blur="$v.autoResolveDuration.$touch"
                        />
                        <span v-if="$v.autoResolveDuration.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.ERROR') }}
            </span>
                    </label>
                </div>
            </div>

            <div
        class="p-4 border-slate-25 dark:border-slate-700 text-black-900 dark:text-slate-300 row"
      >
                <div
          class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 py-4 pr-6 pl-0"
        >
                    <h4 class="block-title text-black-900 dark:text-slate-200">
                        {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.TITLE') }}
                    </h4>
                    <p>
                        {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.NOTE') }}
                    </p>
                </div>
                <div class="p-4 flex-grow-0 flex-shrink-0 flex-[50%]">
                    <woot-code :script="getAccountId" />
                </div>
            </div>

      <!--            d99d-->
      <div
        class="p-4 border-slate-25 dark:border-slate-700 text-black-900 dark:text-slate-300 row"
      >
        <div
          class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 py-4 pr-6 pl-0"
        >
          <h4 class="block-title text-black-900 dark:text-slate-200">
            {{ $t('GENERAL_SETTINGS.FORM.ALLOWED_IPS.TITLE') }}
          </h4>
          <p>
            {{ $t('GENERAL_SETTINGS.FORM.ALLOWED_IPS.NOTE') }}
          </p>
        </div>
        <div class="py-4 pl-4 pr-1 flex-grow-0 flex-shrink-0 flex-[50%]">

            <div class="flex" v-for="(_, index) in allowedIps" :key="index">
              <input :id="'allowedIps-' + index" v-model="allowedIps[index]" type="text"
                     :placeholder="$t('GENERAL_SETTINGS.FORM.ALLOWED_IPS.PLACEHOLDER', {index: index + 1})"
              >
              <div class="w-3">
              <woot-button v-show="index > 0"
                icon="dismiss"
                variant="clear"
                color-scheme="secondary"
                @click.prevent="removeAllowedIp(index)"
              />
              </div>
            </div>

            <woot-button
               v-if="isAddAllowedIpAvailable"
               icon="add"
               color-scheme="success"
               variant="smooth"
               size="small"
               @click.prevent="addAllowedIp"
            >
              {{ $t('GENERAL_SETTINGS.FORM.ALLOWED_IPS.ADD_BUTTON_LABEL') }}
            </woot-button>
          <div v-if="$v.allowedIps.$error" class="error">
              {{ $t('GENERAL_SETTINGS.FORM.ALLOWED_IPS.ERROR') }}
            </div>
          </div>
      </div>
      <!--            .-->

            <div class="text-sm text-center p-4">
                <div>{{ `v${globalConfig.appVersion}` }}</div>
                <div v-if="hasAnUpdateAvailable && globalConfig.displayManifest">
                    {{
                    $t('GENERAL_SETTINGS.UPDATE_CHATWOOT', {
                    latestChatwootVersion: latestChatwootVersion,
                    })
                    }}
                </div>
            <div class="build-id">
          <div>{{ `Build ${globalConfig.gitSha}` }}</div>
        </div>
      </div>

            <woot-submit-button
                    :class="`button nice success button--fixed-top`"
                    :button-text="$t('GENERAL_SETTINGS.SUBMIT')"
                    :loading="isUpdating"
            />
        </form>

        <div v-if="false/* d99d */">
            <button class="button primary" @click="downloadAllConversation" style="margin-inline-end: 1rem">
                {{ $t('GENERAL_SETTINGS.EXPORT_ALL_CONVERSATION') }}
            </button>
            <label v-if="downloadConversationInfo.status !== 'completed' && downloadConversationInfo.message"
                   style="display: inline; font-style: italic; font-size: 0.8rem">
                {{downloadConversationInfo.message}}
                <woot-button
                        color-scheme="info"
                        variant="link"
                        icon="ion-android-refresh"
                        @click="fetchExportStatus"
                >
                </woot-button>
            </label>
            <a v-else-if="downloadConversationInfo.status === 'completed' && downloadConversationInfo.file" :href="downloadConversationInfo.file.url">
                {{downloadConversationInfo.file.name}}
            </a>

        </div>
        <woot-loading-state v-if="uiFlags.isFetchingItem" />

    </div>
</template>

<script>
import {required, minValue, maxValue, ipAddress} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import accountMixin from '../../../../mixins/account';
import ImageUploader from "../../../../components/widgets/forms/ImageUploader";
import { FEATURE_FLAGS } from '../../../../featureFlags';
import semver from 'semver';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { getLanguageDirection } from 'dashboard/components/widgets/conversation/advancedFilterItems/languages';

export default {
  components: {ImageUploader},
  mixins: [accountMixin, alertMixin, configMixin, uiSettingsMixin],
  data() {
    return {
      id: '',
      name: '',
      locale: 'en',
      domain: '',
      supportEmail: '',
      features: {},
      autoResolveDuration: null,
      latestChatwootVersion: null,
      logoFile: '',
      logoUrl: '',
      showLogo: false,
      downloadConversationInfo: {},
      allowedIps: [], // d99d
    };
  },
  validations: {
    name: {
      required,
    },
    locale: {
      required,
    },
    autoResolveDuration: {
      minValue: minValue(1),
      maxValue: maxValue(999),
    },
    allowedIps: {
      $each: {
          ipAddress
      }
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      getAccount: 'accounts/getAccount',
      uiFlags: 'accounts/getUIFlags',
      allConversations: 'getAllConversations',
      allConversationsWithoutPage: 'getAllConversationsExPage',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    showAutoResolutionConfig() {
      return this.isFeatureEnabledonAccount(
        this.accountId,
        FEATURE_FLAGS.AUTO_RESOLVE_CONVERSATIONS
      );
    },
    hasAnUpdateAvailable() {
      if (!semver.valid(this.latestChatwootVersion)) {
        return false;
      }

      return semver.lt(
        this.globalConfig.appVersion,
        this.latestChatwootVersion
      );
    },
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
    isUpdating() {
      return this.uiFlags.isUpdating;
    },

    featureInboundEmailEnabled() {
      return !!this.features.inbound_emails;
    },

    featureCustomReplyDomainEnabled() {
      return (
        this.featureInboundEmailEnabled && !!this.features.custom_reply_domain
      );
    },

    featureCustomReplyEmailEnabled() {
      return (
        this.featureInboundEmailEnabled && !!this.features.custom_reply_email
      );
    },

    getAccountId() {
      return this.id.toString();
    },
    // d99d
    isAddAllowedIpAvailable() {
      return this.allowedIps.length < 5
    },
    // .
  },
  mounted() {
    if (!this.id) { // d99d, but why
      this.initializeAccount();
    }
    this.fetchExportStatus() // d99d
  },
  methods: {
    async initializeAccount() {
      try {
        const {
          name,
          locale,
          id,
          domain,
          support_email,
          features,
          auto_resolve_duration,
          latest_chatwoot_version: latestChatwootVersion,
          logo_url,
          allowed_ips,
        } = this.getAccount(this.accountId);

        this.$root.$i18n.locale = locale;
        this.name = name;
        this.locale = locale;
        this.id = id;
        this.domain = domain;
        this.supportEmail = support_email;
        this.features = features;
        this.autoResolveDuration = auto_resolve_duration;
        this.latestChatwootVersion = latestChatwootVersion;
        this.logoUrl = logo_url;
        this.allowedIps = allowed_ips || [];
        if (!this.allowedIps.length) this.allowedIps.push(undefined)
      } catch (error) {
        // Ignore error
      }
    },

    async updateAccount() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('GENERAL_SETTINGS.FORM.ERROR'));
        return;
      }
      try {
        const allowedIps = this.allowedIps.filter(x=>!!x && x.trim().length > 0);
        await this.$store.dispatch('accounts/update', {
          locale: this.locale,
          domain: this.domain,
          support_email: this.supportEmail,
          auto_resolve_duration: this.autoResolveDuration,
          logo: this.logoFile,
          allowed_ips: allowedIps.length ? allowedIps : null,
        });
        this.logoFile = '';
        this.$root.$i18n.locale = this.locale;
        this.getAccount(this.id).locale = this.locale;
        this.updateDirectionView(this.locale);
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.ERROR'));
      }
    },
    // d99d
    handleImageUpload({file, url}) {
      this.logoFile = file;
      this.logoUrl = url;
    },
    handleImageDelete() {
      this.logoFile = null; //explicit deletion
      this.logoUrl = '';
    },
    async downloadAllConversation() {
      try {
        await this.$store.dispatch('downloadAllConversations')
        this.downloadConversationInfo = {message: this.$t('GENERAL_SETTINGS.EXPORT_ALL_CONVERSATION_STARTED')}
      } catch (error) {
        this.downloadConversationInfo = {}
        this.showAlert(
          error.message || this.$t('IMPORT_CONVERSATIONS.ERROR_MESSAGE')
        );
      }
    },
    async fetchExportStatus() {
      try {
        const {status, message, file} = await this.$store.dispatch('statusOfLastDownloadAllConversations');
        this.downloadConversationInfo = {status, message, file}
      } catch (error) {
        this.showAlert(
          error.message || this.$t('IMPORT_CONVERSATIONS.ERROR_MESSAGE')
        );
      }
    },

    addAllowedIp() {
      if (this.allowedIps.length < 5) {
        this.allowedIps.push(undefined);
      }
    },
    removeAllowedIp(index) {
      this.allowedIps.splice(index, 1);
    },
    // .

    updateDirectionView(locale) {
      const isRTLSupported = getLanguageDirection(locale);
      this.updateUISettings({
        rtl_view: isRTLSupported,
      });
    },
  },
};
</script>

<style scoped lang="scss">
// d99d
.error {
  @apply mt-2 bg-red-100 dark:bg-red-100 rounded-md text-red-800 dark:text-red-800 p-2.5 text-center;
}
</style>
