<template>
  <div class="small-12 medium-12 columns">
  <form
    class="small-12 medium-4 columns"
    @submit.prevent="submit">
  <woot-input
    v-model.trim="phoneNumber"
    type="number"

    :label="$t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WA_WIDGET.PHONE_NUMBER.LABEL')"
    :placeholder="$t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WA_WIDGET.PHONE_NUMBER.PLACEHOLDER')"
  />

    <woot-button
      type="submit"
      :is-disabled="$v.$invalid"
    >
      {{ $t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WA_WIDGET.GENERATE_SCRIPT_BUTTON') }}
    </woot-button>


  </form>
    <label v-if="script" class="columns medium-12" style="margin-top: 2rem">
      {{ $t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WA_WIDGET.SCRIPT.LABEL') }}
      <woot-code :script="script" />
    </label>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import contactMixin from "dashboard/mixins/contactMixin";

export default {
  name: "WaWidget",
  mixins: [contactMixin],
  data() {
    return {
      phoneNumber: undefined,
      script: undefined,
    }
  },
  validations: {
    phoneNumber: {required}
  },
  computed: {
  },
  methods: {
    async submit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }

        const phone = this.israelPhone2e164(this.phoneNumber)?.replace('+', '')
        this.script = `
     <script>
      (function(d,t) {
        var BASE_URL="${window.location.origin}";
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = BASE_URL+"/packs/js/wa_widget.js";
        var options = {
          baseUrl: BASE_URL,
          phoneNumber:"${phone}",
        };
        s.onload = function() {
          window.onlineWaWidget.run(options);
        };
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      })(document,"script");
    <\/script>
        `
      } catch (e) {
        console.error(e)
      }
    },
  }
}
</script>

<style scoped>

</style>
