<template>
  <div class="column content-box overflow-auto">
    <div class="row">
      <div class="small-12 columns integrations-wrap">
        <div class="row integrations">
          <div
            v-for="item in list"
            :key="item.id"
            class=" mb-2 p-3 small-3 cubical"
          >
            <integration-ext99
              class="bg-white dark:bg-slate-800 border border-solid border-slate-75 dark:border-slate-700/50 rounded-sm p-4 integration"
              :integration-id="item.id"
              :integration-logo="item.logo"
              :integration-name="item.name"
              :integration-description="item.description"
              :integration-enabled="item.enabled"
              :integration-action="item.action"
              :integration-path="`settings-integrations${item.isApp ? '/app' : ''}`"
              :disabled="item.disabled"
              :integration="item"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Integration from '../Integration';
import IntegrationExt99 from "../IntegrationExt99.vue";

export default {
  components: {
    IntegrationExt99,
    Integration,
  },
  computed: {
    ...mapGetters({
      integrationsList: 'integrations/getIntegrations',
      appIntegrationsList: 'integrations/getAppIntegrations',
    }),
    list() {
      return [...this.integrationsList, ...this.appIntegrationsList.map(x=>({...x, isApp: true}))]
        .filter(x=>x.visible !== false)
    }
  },
  mounted() {
    this.$store.dispatch('integrations/get');
  },
};
</script>

<style lang="scss" scoped>
.integrations {
  justify-content: space-around;
  .cubical {
    min-height: 360px;
  }
}
</style>
