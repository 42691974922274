<template>
  <div>
    <woot-input
      v-model.trim="phoneNumber"
      type="number"
      class="small-12 medium-4 columns"
      :label="$t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WITH_CUSTOMERS.CLIENT_PHONE_NUMBER.LABEL')"
      :placeholder="$t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WITH_CUSTOMERS.CLIENT_PHONE_NUMBER.PLACEHOLDER')"
    />
    <woot-input
      v-model.trim="clientName"
      class="small-12 medium-4 columns"
      :label="$t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WITH_CUSTOMERS.CLIENT_NAME.LABEL')"
      :placeholder="$t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WITH_CUSTOMERS.CLIENT_NAME.PLACEHOLDER')"
    />
    <label class="columns medium-12" style="margin-top: 4rem">
      {{ $t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WITH_CUSTOMERS.RESULT_LABEL') }}
      <woot-code :script="script" />
    </label>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import contactMixin from "dashboard/mixins/contactMixin";
import {mapGetters} from "vuex";

export default {
  name: "ChatWithCustomers",
  mixins: [contactMixin],
  data() {
    return {
      phoneNumber: undefined,
      clientName: undefined,
    }
  },
  validations: {
    phoneNumber: {required}
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      getAccount: 'accounts/getAccount',
    }),
    account() {
      return this.getAccount(this.accountId);
    },
    iPracticomFeatures() {
      return this.account.platform_features?.iPracticom;
    },
    script() {
      const phone = this.israelPhone2e164(this.phoneNumber || '972501234567')?.replace('+', '')
      const name = this.clientName || (!this.phoneNumber && 'John') || '';
      let platformLink = 'https://chat.99digital.co.il';
      if (this.iPracticomFeatures) {
        platformLink = 'https://chat.ip-com.co.il';
      }
      return `${platformLink}/${this.accountId}/${phone}/${name}`;
    },
  },
  methods: {
    async submit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }

        await copyTextToClipboard(this.script);
        bus.$emit('newToastMessage', this.$t('COMPONENTS.CODE.COPY_SUCCESSFUL'));
      } catch (e) {
        console.error(e)
      }
    },
  }
}
</script>

<style scoped>

</style>
